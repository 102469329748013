<template>
  <div
    v-if="
      isError !== null &&
      healthcheckStore.hasError === false &&
      userStore.user?.account_type === AccountTypeEnum.Company
    "
    class="flex min-h-full"
  >
    <TransitionRoot as="div" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <Button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </Button>
                </div>
              </TransitionChild>
              <div class="flex flex-shrink-0 px-4">
                <img
                  class="h-8 w-auto"
                  src="~/assets/svgs/logo.svg"
                  alt="Krožnik logo v obliki krožnika"
                />
              </div>
              <div class="items-center px-4 mt-3">
                <p>
                  Podjetje:
                  <span class="font-bold text-green-main">
                    {{ companyStore.name }}
                  </span>
                </p>
              </div>
              <div v-if="branchOfficeStore.branchOffices.length > 0" class="mt-4 px-3 ml-1">
                <p>Upravljate s poslovalnico:</p>
                <Select
                  v-if="branchOfficeStore.branchOffices.length > 1"
                  v-model="selectedBranchOffice"
                  class="mb-1"
                  :options="
                    branchOfficeStore.branchOffices.map((bo) => {
                      return { value: bo.id, text: bo.name }
                    })
                  "
                />
                <p
                  v-if="branchOfficeStore.branchOffices.length === 1"
                  class="font-bold text-green-main"
                >
                  {{ branchOfficeStore.branchOffices[0].name }}
                </p>
              </div>
              <nav class="mt-5 h-full flex-shrink-0 overflow-y-auto" aria-label="Sidebar">
                <div class="space-y-1 px-2">
                  <NuxtLink
                    v-for="item in navigation"
                    :key="item.name"
                    :to="item.href"
                    :class="[
                      item.current
                        ? '-ml-1 font-bold border-r-green-main'
                        : 'text-black hover:text-green-main border-r-white',
                      'group flex items-center px-2 py-2 leading-6 justify-between border-4 border-transparent',
                    ]"
                    :aria-current="item.current ? 'page' : undefined"
                  >
                    {{ item.name }}
                    <div v-if="item.counter" class="bg-black-light px-2 rounded text-white">
                      {{ item.counter }}
                    </div>
                  </NuxtLink>
                </div>
                <div class="mt-4 ml-4 mr-4 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="pt-4">
                  <div class="space-y-1 px-2">
                    <NuxtLink
                      v-for="item in secondaryNavigation"
                      :key="item.name"
                      :to="item.href"
                      :class="[
                        item.current
                          ? '-ml-1 border-4 border-transparent font-bold border-r-green-main'
                          : 'text-black hover:text-green-main',
                        'group flex items-center px-2 py-2 leading-6 rounded-md',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        class="mr-4 h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </NuxtLink>
                  </div>
                </div>
                <div class="mt-12 ml-4 mr-4">
                  <p>Status poslovalnice:</p>
                  <p
                    v-if="branchOfficeStore.getSelectedBranchOffice?.is_operational"
                    class="text-green-main font-bold"
                  >
                    Se prikazuje v aplikaciji
                  </p>
                  <p v-else class="text-error-main font-bold">Se ne prikazuje v aplikaciji</p>
                  <div
                    v-if="branchOfficeStore.getSelectedBranchOffice?.is_operational"
                    class="mt-3"
                  >
                    <template
                      v-if="
                        isBranchOfficeOpenToday(
                          branchOfficeStore.getSelectedBranchOffice?.opening_hours
                        )
                      "
                    >
                      <p>Današnje prevzemno okno:</p>
                      <p>
                        {{
                          getPickupWindowForToday(
                            branchOfficeStore.getSelectedBranchOffice?.opening_hours
                          )
                        }}
                      </p>
                    </template>
                    <template v-else>
                      <p>Poslovalnica je danes zaprta</p>
                    </template>
                  </div>
                </div>
              </nav>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-grow flex-col overflow-y-auto bg-white shadow-md pt-5 pl-4 pb-4">
        <div class="flex flex-shrink-0 items-center px-4">
          <img
            class="h-8 w-auto"
            src="~/assets/svgs/logo.svg"
            alt="Krožnik logo v obliki krožnika"
          />
        </div>
        <div class="px-3 ml-1 mt-5">
          <p>
            Podjetje:
            <span class="font-bold text-green-main">
              {{ companyStore.name }}
            </span>
          </p>
        </div>
        <div v-if="branchOfficeStore.branchOffices.length > 0" class="mt-4 px-3 ml-1">
          <p>Upravljate s poslovalnico:</p>
          <Select
            v-if="branchOfficeStore.branchOffices.length > 1"
            v-model="selectedBranchOffice"
            class="mb-1"
            :options="
              branchOfficeStore.branchOffices.map((bo) => {
                return { value: bo.id, text: bo.name }
              })
            "
          />
          <p v-if="branchOfficeStore.branchOffices.length === 1" class="font-bold text-green-main">
            {{ branchOfficeStore.branchOffices[0].name }}
          </p>
        </div>
        <nav class="mt-5 flex flex-1 flex-col overflow-y-auto" aria-label="Sidebar">
          <div class="space-y-1 px-2">
            <NuxtLink
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              :class="[
                item.current
                  ? '-ml-1 font-bold border-r-green-main'
                  : 'text-black hover:text-green-main border-r-white',
                'group flex items-center px-2 py-2 leading-6 justify-between border-4 border-transparent',
              ]"
              :aria-current="item.current ? 'page' : undefined"
            >
              {{ item.name }}
              <div v-if="item.counter" class="bg-black-light px-2 rounded text-white">
                {{ item.counter }}
              </div>
            </NuxtLink>
          </div>
          <div class="mt-4 ml-4 mr-4 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="pt-6">
            <div class="space-y-1 px-2">
              <NuxtLink
                v-for="item in secondaryNavigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.current
                    ? '-ml-1 border-4 border-transparent font-bold border-r-green-main'
                    : 'text-black hover:text-green-main',
                  'group flex items-center px-2 py-2 leading-6 rounded-md',
                ]"
                :target="['Pomoč', 'Politika zasebnosti'].includes(item.name) ? '_blank' : null"
              >
                <component :is="item.icon" class="mr-4 h-6 w-6 text-black" aria-hidden="true" />
                {{ item.name }}
              </NuxtLink>
            </div>
          </div>
        </nav>
        <div class="mt-4 ml-4 mr-4">
          <p>Status poslovalnice:</p>
          <p
            v-if="branchOfficeStore.getSelectedBranchOffice?.is_operational"
            class="text-green-main font-bold"
          >
            Se prikazuje v aplikaciji
          </p>
          <p v-else class="text-error-main font-bold">Se ne prikazuje v aplikaciji</p>
          <div v-if="branchOfficeStore.getSelectedBranchOffice?.is_operational" class="mt-3">
            <template
              v-if="
                isBranchOfficeOpenToday(branchOfficeStore.getSelectedBranchOffice?.opening_hours)
              "
            >
              <p>Današnje prevzemno okno:</p>
              <p>
                {{
                  getPickupWindowForToday(branchOfficeStore.getSelectedBranchOffice?.opening_hours)
                }}
              </p>
            </template>
            <template v-else>
              <p>Poslovalnica je danes zaprta</p>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-1 flex-col lg:pl-72">
      <div
        class="flex h-16 justify-between lg:justify-end border-b border-gray-200 bg-white lg:border-none"
      >
        <button
          type="button"
          class="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3CenterLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <!-- Search bar -->
        <div class="flex justify-end px-4 sm:px-6 lg:max-w-6xl lg:px-8">
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none lg:rounded-md lg:p-2 lg:hover:bg-gray-50"
                >
                  <UserIcon class="h-5 w-5" />
                  <span class="ml-3 hidden text-sm font-medium text-gray-700 lg:block"
                    ><span class="sr-only">Open user menu for </span
                    >{{ userStore.user?.name }}</span
                  >
                  <ChevronDownIcon
                    class="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-md"
                >
                  <MenuItem v-slot="{ active }">
                    <div
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      @click="logout"
                    >
                      Izpis iz sistema
                    </div>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <div v-if="isAllDataLoaded">
        <NuxtLoadingIndicator />
        <ErrorBanner v-if="healthcheckStore.hasError" class="w-full md:w-1/3 mx-auto" />
        <slot />
      </div>
    </div>
  </div>
  <div v-else-if="healthcheckStore.hasError">
    <Container>
      <Title class="w-full md:w-1/3">Nekaj je šlo narobe</Title>
      <Title subtitle>Stran se ne more povezati na strežnik.</Title>
      <Title subtitle>Prosimo poskusite čez nekaj trenutkov.</Title>
      <Title>Možnosti zakaj stran ne dela?</Title>
      <Title subtitle>Napaka je na strežniku, ki jo že popravljamo.</Title>
      <Title subtitle>Nadgrajujemo sistem, ki bo dostopen v kratkem.</Title>
    </Container>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef, FunctionalComponent, Ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3CenterLeftIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, UserIcon } from '@heroicons/vue/20/solid'
import { useToast } from 'vue-toastification'
import Title from '~/components/common/Title.vue'
import { useUserStore } from '~/stores/user.store'
import {
  AccountTypeEnum,
  AuthApi,
  Configuration,
  HealthcheckApi,
  type OpeningHoursDto,
} from '~/client_api'
import { axiosInstance, axiosInstanceAuth } from '~/client_api/exios'
import {
  useBranchOfficeStore,
  useCompanyMemberStore,
  useCompanyStore,
} from '~/stores/company.store'
import { Routes } from '~/routing/routes'
import Button from '~/components/common/Button.vue'
import { getSelectedBranchOffice, setSelectedBranchOffice } from '~/services/branchOfficeService'
import Select from '~/components/common/Select.vue'
import { useStatisticsStore } from '~/stores/statistics'
import { HEALTHCHECK_INTERVAL } from '~/utils/healthcheck'
import { useHealthcheckStore } from '~/stores/healthcheck'
import ErrorBanner from '~/components/features/ErrorBanner.vue'
import Container from '~/components/common/Container.vue'

const toaster = useToast()

const statisticsStore = useStatisticsStore()

const router = useRouter()
const isRouteSelected = (prefix: string) => {
  return router.currentRoute.value.path === prefix
}

interface Navigation {
  name: string
  href: string
  current?: boolean
  icon?: string | FunctionalComponent
}

const navigation = computed<Navigation[]>(() => [
  { name: 'Nadzorna plošča', href: Routes.Dashboard, current: isRouteSelected(Routes.Dashboard) },
  {
    name: 'Naročila',
    href: Routes.Orders,
    current: isRouteSelected(Routes.Orders),
    counter: statisticsStore.number_of_reserved_orders,
  },
  { name: 'Inventar', href: Routes.Inventory, current: isRouteSelected(Routes.Inventory) },
  {
    name: 'Poslovalnice',
    href: Routes.SettingsBranchOffices,
    current: isRouteSelected(Routes.SettingsBranchOffices),
  },
])

const secondaryNavigation: ComputedRef<Navigation[]> = computed(() => [
  {
    name: 'Nastavitve',
    href: Routes.Settings,
    icon: CogIcon,
    current: isRouteSelected(Routes.Settings),

  },
  {
    name: 'Pomoč',
    // href: Routes.Support,
    href: 'https://www.kroznik.si/podpora',
    icon: QuestionMarkCircleIcon,
    // current: isRouteSelected(Routes.Support),

  },
  {
    name: 'Politika zasebnosti',
    // href: Routes.Policy,
    href: 'https://www.kroznik.si/politika-zasebnosti',
    icon: ShieldCheckIcon,
    // current: isRouteSelected(Routes.Policy),
  },
])

const headers = useRequestHeaders()
const { data: companyData, error: companyFetchError } = await useFetch('/api/company-details', {
  headers,
})
const { data: branchOfficesData, error: branchOfficesFetchError } = await useFetch(
  '/api/branch-offices',
  { headers }
)

const isError: Ref<boolean | null> = ref(null)

const userStore = useUserStore()
const companyStore = useCompanyStore()
const companyMemberStore = useCompanyMemberStore()
const branchOfficeStore = useBranchOfficeStore()

const logout = () => {
  new AuthApi(new Configuration(), '', axiosInstanceAuth).authDjLogoutCreate().then(() => {
    userStore.isLoggedIn = false
    router.replace(Routes.Home)
    toaster.success('Uspešno ste bili izpisani iz sistema')
  })
}

const loadedElements = reactive({
  company: false,
  branchOffice: false,
})

const isAllDataLoaded = computed(() => {
  return Object.values(loadedElements).every((v) => v)
})

const selectedBranchOffice: Ref<number | null> = ref(null)
watch(selectedBranchOffice, (value) => {
  if (value === null) {
    return
  }
  setSelectedBranchOffice(value)
  branchOfficeStore.selectedBranchOfficeId = value
})

onMounted(() => {
  if (companyFetchError.value !== null) {
    isError.value = true
    return
  }
  companyStore.storeInitial(companyData.value.company)
  companyMemberStore.storeInitial(companyData.value.member)
  isError.value = false
  loadedElements.company = true

  loadedElements.branchOffice = branchOfficesData.value

  branchOfficeStore.setupWith(branchOfficesData.value)
  loadedElements.branchOffice = true
  setupBranchOffice()
  statisticsStore.getStatistics(getSelectedBranchOffice() ?? -1)
})

const healthcheckStore = useHealthcheckStore()
let timer: string | number | NodeJS.Timer | null | undefined = null
onMounted(() => {
  new HealthcheckApi(new Configuration(), '', axiosInstance)
    .healthcheckRetrieve()
    .then(() => {
      healthcheckStore.hasError = false
    })
    .catch(() => {
      healthcheckStore.hasError = true
    })
  timer = setInterval(() => {
    new HealthcheckApi(new Configuration(), '', axiosInstance)
      .healthcheckRetrieve()
      .then(() => {
        healthcheckStore.hasError = false
      })
      .catch(() => {
        healthcheckStore.hasError = true
      })
  }, HEALTHCHECK_INTERVAL)
})
onUnmounted(() => {
  clearInterval(timer)
})

const setupBranchOffice = () => {
  selectedBranchOffice.value = getSelectedBranchOffice()
}

const sidebarOpen = ref(false)

const getPickupWindowForToday = (openingHours: OpeningHoursDto[]): string => {
  const today = new Date()
  const weekday = today.getDay()
  let correctWeekDay = weekday - 1
  if (correctWeekDay < 0) {
    correctWeekDay = 6
  }
  const todaysOpeningHours = openingHours[correctWeekDay]
  return `${todaysOpeningHours.pickup_begins_at.substring(
    0,
    5
  )} - ${todaysOpeningHours.pickup_ends_at.substring(0, 5)} `
}

const isBranchOfficeOpenToday = (openingHours: OpeningHoursDto[]): boolean => {
  const today = new Date()
  const weekday = today.getDay()
  let correctWeekDay = weekday - 1
  if (correctWeekDay < 0) {
    correctWeekDay = 6
  }
  const todaysOpeningHours = openingHours[correctWeekDay]
  return todaysOpeningHours.is_open
}
</script>

<style scoped></style>
